import React from "react"

import { Helmet } from "react-helmet"
import HomepageSignup from "../components/Homepage/HomepageSignup"
import NavBar from "../components/NavBar"

import "react-datepicker/dist/react-datepicker.css"
import { ParallaxProvider } from "react-scroll-parallax"

const join = () => {
  return (
    <ParallaxProvider>
      <NavBar />

      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="author" content="C.Liu" />

        <title>Join | Café Kraken</title>
        <meta
          name="description"
          content="Sign up here for a FREE treat - It's on us!"
        />
      </Helmet>

      <section className="join">
        <HomepageSignup />
      </section>
    </ParallaxProvider>
  )
}
export default join
